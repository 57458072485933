import {Component, Inject, OnDestroy, OnInit, ElementRef, Renderer2} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {Title} from '@angular/platform-browser';

import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import * as Waves from 'node-waves';

import {CoreMenuService} from '@core/components/core-menu/core-menu.service';
import {CoreSidebarService} from '@core/components/core-sidebar/core-sidebar.service';
import {CoreConfigService} from '@core/services/config.service';
import {CoreLoadingScreenService} from '@core/services/loading-screen.service';
import {CoreTranslationService} from '@core/services/translation.service';

import {menu} from 'app/menu/menu';
import {locale as menuEnglish} from 'app/menu/i18n/en';
import {locale as menuItalian} from 'app/menu/i18n/it';
import {AuthenticationService} from "app/auth/service";
import {ApplicationService} from "@core/services/application.service";
import {Theme} from "@core/types/app/theme";
import {Menu} from "../@core/types/data/menu";
import { ThemeLogoService} from "./services/theme-logo.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  coreConfig: any;
  menu: any[] = [];
  defaultLanguage: 'it'; // This language will be used as a fallback when a translation isn't found in the current language
  appLanguage: 'it'; // Set application default language i.e fr

  // Private
  private _unsubscribeAll: Subject<any>;
  private currentUser: any;
  private menuItems: Menu[];

  /**
   * Constructor
   *
   * @param {DOCUMENT} document
   * @param {Title} _title
   * @param {Renderer2} _renderer
   * @param {ElementRef} _elementRef
   * @param {CoreConfigService} _coreConfigService
   * @param {CoreSidebarService} _coreSidebarService
   * @param {CoreLoadingScreenService} _coreLoadingScreenService
   * @param {CoreMenuService} _coreMenuService
   * @param {CoreTranslationService} _coreTranslationService
   * @param {TranslateService} _translateService
   * @param _authenticationService
   */
  constructor(
    @Inject(DOCUMENT) private document: any,
    private _title: Title,
    private _renderer: Renderer2,
    private _elementRef: ElementRef,
    public _coreConfigService: CoreConfigService,
    private _coreSidebarService: CoreSidebarService,
    private _coreLoadingScreenService: CoreLoadingScreenService,
    private _coreMenuService: CoreMenuService,
    private _coreTranslationService: CoreTranslationService,
    private _translateService: TranslateService,
    private _authenticationService: AuthenticationService,
    private _applicationService: ApplicationService,
    private themeLogoService: ThemeLogoService

  ) {
    // Get the application main menu






    // Add languages to the translation service
    this._translateService.addLangs(['it', 'en']);

    // This language will be used as a fallback when a translation isn't found in the current language
    this._translateService.setDefaultLang('it');

    // Set the translations for the menu
    this._coreTranslationService.translate(menuItalian, menuEnglish);

    // Set the private defaults
    this._unsubscribeAll = new Subject();

    this._authenticationService.fetchCurrentUser(true)

    // this._authenticationService
    //   .signin
    //   .then(() => {
    //     this._coreLoadingScreenService.hide();
    //   })
  }

  // Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this._authenticationService.currentUser$.subscribe(user => {
      this.currentUser = user;
      this.retrieveMenuItems().then(() => {
        this._coreMenuService.register('main', this.menuItems);
        this._coreMenuService.setCurrentMenu('main');
      })
        .catch(error => {
          console.error('Error retrieving menu items:', error);
        });
      this._coreMenuService.register('main', this.menuItems);
      this._coreMenuService.setCurrentMenu('main');
    });
    Waves.init();
    this._coreLoadingScreenService.hide();
    // Subscribe to config changes
    this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
      this.coreConfig = config;
      this._applicationService.getTheme().then((themeObservable) => {
        themeObservable.subscribe((theme: Theme) => {
          // Set primary and secondary colors dynamically
          if (theme.colors && theme.colors.primary && theme.colors.secondary) {
            this.themeLogoService.setThemeLogo(theme.app.logo.light) ;
            const primaryColors = theme.colors.primary;
            const secondaryColors = theme.colors.secondary;
            Object.keys(primaryColors).forEach((key: string) => {
              const root = document.documentElement;
              // transform hex color to rgb values
              const primaryRgb = primaryColors[500].match(/\w\w/g).map(x => parseInt(x, 16));
              const secondaryRgb = secondaryColors[500].match(/\w\w/g).map(x => parseInt(x, 16));
              root.style.setProperty('--primary-color', primaryColors['500']);
              root.style.setProperty('--primary-color-rgb',primaryRgb.join(', '));
              root.style.setProperty('--secondary-color', secondaryColors['500']);
              root.style.setProperty('--secondary-color-rgb',secondaryRgb.join(', '));
              this._renderer.setStyle(
                document.documentElement,
                `--primary-${key}`,
                primaryColors[key],
              );
              const cssText = `
        :root {
          --primary: ${theme.colors.primary['500']};
          --secondary: ${theme.colors.secondary['500']};
        }
      `;
              const style = this._renderer.createElement('style');
              style.type = 'text/css';
              style.appendChild(this._renderer.createText(cssText));
              this._renderer.appendChild(this._elementRef.nativeElement, style);
            });

            Object.keys(secondaryColors).forEach((key: string) => {
              this._renderer.setStyle(
                document.documentElement,
                `--secondary-${key}`,
                secondaryColors[key]
              );
            });
          }
        });
      });
      // Set application default language.

      // Change application language? Read the ngxTranslate Fix

      // ? Use app-config.ts file to set default language
      const appLanguage = this.coreConfig.app.appLanguage || 'it';
      this._translateService.use(appLanguage);

      // ? OR
      // ? User the current browser lang if available, if undefined use 'en'
      // const browserLang = this._translateService.getBrowserLang();
      // this._translateService.use(browserLang.match(/en|fr|de|pt/) ? browserLang : 'en');

      /**
       * ! Fix : ngxTranslate
       * ----------------------------------------------------------------------------------------------------
       */

      /**
       *
       * Using different language than the default ('en') one i.e French?
       * In this case, you may find the issue where application is not properly translated when your app is initialized.
       *
       * It's due to ngxTranslate module and below is a fix for that.
       * Eventually we will move to the multi language implementation over to the Angular's core language service.
       *
       **/

      // Set the default language to 'en' and then back to 'fr'.

      setTimeout(() => {
        this._translateService.setDefaultLang('en');
        this._translateService.setDefaultLang(appLanguage);
      });

      /**
       * !Fix: ngxTranslate
       * ----------------------------------------------------------------------------------------------------
       */

      // Layout
      //--------

      // Remove default classes first
      this._elementRef.nativeElement.classList.remove(
        'vertical-layout',
        'vertical-menu-modern',
        'horizontal-layout',
        'horizontal-menu'
      );
      // Add class based on config options
      if (this.coreConfig.layout.type === 'vertical') {
        this._elementRef.nativeElement.classList.add('vertical-layout', 'vertical-menu-modern');
      } else if (this.coreConfig.layout.type === 'horizontal') {
        this._elementRef.nativeElement.classList.add('horizontal-layout', 'horizontal-menu');
      }

      // Navbar
      //--------

      // Remove default classes first
      this._elementRef.nativeElement.classList.remove(
        'navbar-floating',
        'navbar-static',
        'navbar-sticky',
        'navbar-hidden'
      );

      // Add class based on config options
      if (this.coreConfig.layout.navbar.type === 'navbar-static-top') {
        this._elementRef.nativeElement.classList.add('navbar-static');
      } else if (this.coreConfig.layout.navbar.type === 'fixed-top') {
        this._elementRef.nativeElement.classList.add('navbar-sticky');
      } else if (this.coreConfig.layout.navbar.type === 'floating-nav') {
        this._elementRef.nativeElement.classList.add('navbar-floating');
      } else {
        this._elementRef.nativeElement.classList.add('navbar-hidden');
      }

      // Footer
      //--------

      // Remove default classes first
      this._elementRef.nativeElement.classList.remove('footer-fixed', 'footer-static', 'footer-hidden');

      // Add class based on config options
      if (this.coreConfig.layout.footer.type === 'footer-sticky') {
        this._elementRef.nativeElement.classList.add('footer-fixed');
      } else if (this.coreConfig.layout.footer.type === 'footer-static') {
        this._elementRef.nativeElement.classList.add('footer-static');
      } else {
        this._elementRef.nativeElement.classList.add('footer-hidden');
      }

      // Blank layout
      if (
        this.coreConfig.layout.menu.hidden &&
        this.coreConfig.layout.navbar.hidden &&
        this.coreConfig.layout.footer.hidden
      ) {
        this._elementRef.nativeElement.classList.add('blank-page');
        // ! Fix: Transition issue while coming from blank page
        this._renderer.setAttribute(
          this._elementRef.nativeElement.getElementsByClassName('app-content')[0],
          'style',
          'transition:none'
        );
      } else {
        this._elementRef.nativeElement.classList.remove('blank-page');
        // ! Fix: Transition issue while coming from blank page
        setTimeout(() => {
          this._renderer.setAttribute(
            this._elementRef.nativeElement.getElementsByClassName('app-content')[0],
            'style',
            'transition:300ms ease all'
          );
        }, 0);
        // If navbar hidden
        if (this.coreConfig.layout.navbar.hidden) {
          this._elementRef.nativeElement.classList.add('navbar-hidden');
        }
        // Menu (Vertical menu hidden)
        if (this.coreConfig.layout.menu.hidden) {
          this._renderer.setAttribute(this._elementRef.nativeElement, 'data-col', '1-column');
        } else {
          this._renderer.removeAttribute(this._elementRef.nativeElement, 'data-col');
        }
        // Footer
        if (this.coreConfig.layout.footer.hidden) {
          this._elementRef.nativeElement.classList.add('footer-hidden');
        }
      }

      // Skin Class (Adding to body as it requires highest priority)
      if (this.coreConfig.layout.skin !== '' && this.coreConfig.layout.skin !== undefined) {
        this.document.body.classList.remove('default-layout', 'bordered-layout', 'dark-layout', 'semi-dark-layout');
        this.document.body.classList.add(this.coreConfig.layout.skin + '-layout');
      }
    });

    // Set the application page title
    this._title.setTitle(this.coreConfig.app.appTitle);
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle sidebar open
   *
   * @param key
   */
  toggleSidebar(key): void {
    this._coreSidebarService.getSidebarRegistry(key).toggleOpen();
  }

  retrieveMenuItems(): Promise<void> {
    return new Promise((resolve, reject) => {
      this._applicationService.getMenu()
        .then(menuObservable => {
          menuObservable.subscribe(menuItems => {
            this.menuItems = menuItems;
            resolve(); // Resolve the promise once menu items are fetched
          });
        })
        .catch(error => {
          reject(error); // Reject the promise if there's an error fetching menu items
        });
    });
  }
}
