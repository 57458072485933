export const locale = {
  lang: 'en',
  data: {
    MENU: {
      HOME: 'Home',
      SPARE_PARTS: 'Spare Parts',
      PROMO: 'Promo',
      LUBRICANTS: 'Lubricants',
      BATTERIES: 'Batteries',
      TIRES: 'Tires',
      GPL: 'GPL - Metano',
      UNIVERSAL: 'Universal',
      ACCESSORIES: 'Accessories',
    }
  }
};
