import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { BehaviorSubject, Observable } from "rxjs";
import { Theme, ThemeAnimation, ThemeSkin } from "@core/types/app/theme";
import { Menu } from "../types/data/menu";
import { AppTheme } from "app/app.theme";
import { DeepMerge } from "@core/helpers/Object";
import { AppMenu } from "app/app.menu";
import { environment } from "../../environments/environment";
import Swal from "sweetalert2";

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {

  // -----------------------------------------------------------------------------------------------------
  // @ private
  // -----------------------------------------------------------------------------------------------------

  private _theme: BehaviorSubject<Theme> = new BehaviorSubject({} as Theme);
  private _menu: BehaviorSubject<Menu[]> = new BehaviorSubject([] as Menu[]);
  private _title: BehaviorSubject<string> = new BehaviorSubject('');
  private _logo: BehaviorSubject<string> = new BehaviorSubject('');
  private _logoSquare: BehaviorSubject<string> = new BehaviorSubject('');
  private _icon: BehaviorSubject<string> = new BehaviorSubject('');
  private _language: BehaviorSubject<string> = new BehaviorSubject('it');
  private _skin: BehaviorSubject<ThemeSkin> = new BehaviorSubject<ThemeSkin>(ThemeSkin.LIGHT);
  private _animation: BehaviorSubject<ThemeAnimation> = new BehaviorSubject<ThemeAnimation>(ThemeAnimation.ZOOM_IN);
  private _headerHidden: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private _sidebarHidden: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private _footerHidden: BehaviorSubject<boolean> = new BehaviorSubject(false);


  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  // Get the config
  get theme(): Observable<Theme> {
    return this._theme.asObservable()
  }

  get menu(): Observable<Menu[]> {
    return this._menu.asObservable()
  }

  get title(): Observable<string> {
    return this._title.asObservable();
  }

  get logo(): Observable<string> {
    return this._logo.asObservable();
  }

  get logoSquare(): Observable<string> {
    return this._logoSquare.asObservable();
  }

  get icon(): Observable<string> {
    return this._icon.asObservable();
  }

  get language(): Observable<string> {
    return this._language.asObservable();
  }

  get skin(): Observable<ThemeSkin> {
    return this._skin.asObservable();
  }

  get animation(): Observable<ThemeAnimation> {
    return this._animation.asObservable();
  }

  get headerHidden(): Observable<boolean> {
    return this._headerHidden.asObservable();
  }

  get sidebarHidden(): Observable<boolean> {
    return this._sidebarHidden.asObservable();
  }

  get footerHidden(): Observable<boolean> {
    return this._footerHidden.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------

  constructor(
    private _apiService: ApiService,
  ) {
    this._theme.subscribe((theme) => {
      if (!theme.layout || !theme.app || !theme.colors) {
        return;
      }
      if (theme.layout.skin !== this._skin.value) {
        this._skin.next(theme.layout.skin)
      }
      if (theme.layout.animation !== this._animation.value) {
        this._animation.next(theme.layout.animation)
      }

      if (theme.app.title !== this._title.value) {
        this._title.next(theme.app.title)
      }
      if (theme.app.language !== this._language.value) {
        this._language.next(theme.app.language)
      }

      // Dark and light skins
      if (theme.layout.skin === ThemeSkin.LIGHT) {
        if (theme.app.icon.light !== this._icon.value) {
          this._icon.next(theme.app.icon.light || theme.app.icon.dark || '')
        }
        if (theme.app.logo.light !== this._logo.value) {
          this._logo.next(theme.app.logo.light || theme.app.logo.dark || '')
        }
        if (theme.app.icon.light !== this._icon.value) {
          this._icon.next(theme.app.icon.light || theme.app.icon.dark || '')
        }
      } else {
        if (theme.app.icon.dark !== this._icon.value) {
          this._icon.next(theme.app.icon.dark || theme.app.icon.light || '')
        }
        if (theme.app.logo.dark !== this._logo.value) {
          this._logo.next(theme.app.logo.dark || theme.app.logo.light || '')
        }
        if (theme.app.icon.dark !== this._icon.value) {
          this._icon.next(theme.app.icon.dark || theme.app.icon.light || '')
        }
      }

      if (theme.layout.header.hidden !== this._headerHidden.value) {
        this._headerHidden.next(theme.layout.header.hidden)
      }
      if (theme.layout.sidebar.hidden !== this._sidebarHidden.value) {
        this._sidebarHidden.next(theme.layout.sidebar.hidden)
      }
      if (theme.layout.footer.hidden !== this._footerHidden.value) {
        this._footerHidden.next(theme.layout.footer.hidden)
      }
    });
    this._init();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  private _init() {
    this._theme.next(DeepMerge({}, AppTheme))
  }


  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------
  getTheme(): Promise<Observable<Theme>> {
    return new Promise((resolve, reject) => {
      this._apiService.make('application', 'theme')
        .then((response) => {
          if (!response) {
            return
          }
          if (parseFloat(environment.version) < parseFloat(response.app.version)) {
            // @ts-ignore
            location.reload(true);
          }
          this._theme.next(response as Theme)
          const primaryColors = response.colors.primary;
          const secondaryColors = response.colors.secondary;
          const root = document.documentElement;
          // transform hex color to rgb values
          const primaryRgb = primaryColors[500].match(/\w\w/g).map(x => parseInt(x, 16));
          const secondaryRgb = secondaryColors[500].match(/\w\w/g).map(x => parseInt(x, 16));
          root.style.setProperty('--primary-color', primaryColors['500']);
          root.style.setProperty('--primary-color-rgb', primaryRgb.join(', '));
          root.style.setProperty('--secondary-color', secondaryColors['500']);
          root.style.setProperty('--secondary-color-rgb', secondaryRgb.join(', '));
          resolve(this._theme.asObservable())
        })
        .catch((err) => {
          switch (err.status) {
            case 401:
              reject('Credenziali errate')
              break
            case 404:
              reject('Utente non trovato')
              break
            default:
              reject('Errore sconosciuto')
              break
          }
        })
    })
  }

  getMenu(): Promise<Observable<Menu[]>> {
    return new Promise((resolve, reject) => {
      this._apiService.make('application', 'menu')
        .then((response) => {
          if (!response) {
            return
          }
          this._menu.next(response as Menu[])
          resolve(this._menu.asObservable())
        })
        .catch((err) => {
          switch (err.status) {
            case 401:
              reject('Credenziali errate')
              break
            case 404:
              reject('Utente non trovato')
              break
            default:
              reject('Errore sconosciuto')
              break
          }
        })
    })
  }

  // selectMenu(id: string): void {
  //   const menu = this._menu.value
  //   menu.forEach((group: Menu) => {
  //     let children = group.children
  //     while (children && children.length > 0) {
  //       children.forEach((item) => item.selected = false)
  //       const item = children.find((item) => item.id === id)
  //       if (item) {
  //         item.selected = true
  //       }
  //       children = children.reduce((acc, item) => acc.concat(item.children || []), [] as Menu[])
  //     }
  //     return false
  //   })
  //   this._menu.next(menu)
  // }

  setTitle(title?: string, config: { reverse: boolean; only: boolean; separator: string; } = {
    reverse: false,
    only: false,
    separator: ' | '
  }) {
    const titleElems = [];
    if (!config.only) {
      titleElems.push(AppTheme.app.title)
    }
    if (title) {
      titleElems.push(title)
    }
    if (config.reverse) {
      titleElems.reverse()
    }
    this._title.next(titleElems.join(config.separator))
  }

  layoutFullWidth(): void {
    this._theme.value.layout.header.hidden = true
    this._theme.value.layout.sidebar.hidden = true
    this._theme.value.layout.footer.hidden = true
    this._theme.next(this._theme.value)
  }

  layoutContentWidth(): void {
    this._theme.value.layout.header.hidden = AppTheme.layout.header.hidden
    this._theme.value.layout.sidebar.hidden = AppTheme.layout.sidebar.hidden
    this._theme.value.layout.footer.hidden = AppTheme.layout.footer.hidden
    this._theme.next(this._theme.value)
  }

  addNewCart(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._apiService.make('sales', 'addCart')
        .then((response) => {
          resolve(response);
        })
    });
  }

  cancelSubscription() {
    return new Promise((resolve, reject) => {
      this._apiService.make('sales', 'cancelSubscription')
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.error(error);
          Swal.fire('Errore!', 'Errore durante l\'annullamento dell\'abbonamento', 'error');
        } );
    })
      .catch((error) => {
        console.error(error);
        Swal.fire('Errore!', 'Errore durante l\'annullamento dell\'abbonamento', 'error');
      } );
  }
}
