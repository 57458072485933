/**
 * @description This function is used to merge two or more objects
 */
type DeepMergeOverload = {
  (target: Number, ...source: any[]): Number;
  (target: String, ...source: any[]): String;
  (target: any[], ...source: any[]): any[];
  (target: {}, ...source: any[]): any;
}

export const DeepMerge: DeepMergeOverload = (target: any, ...source: any[]) => {
  if (!source.length) return target;
  const sourceValue = source.shift();
  if (isMergebleObject(target) && isMergebleObject(sourceValue)) {
    Object.keys(sourceValue).forEach(key => {
      if (isMergebleObject(sourceValue[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        DeepMerge(target[key], sourceValue[key]);
      } else {
        Object.assign(target, { [key]: sourceValue[key] });
      }
    });
  }
  return DeepMerge(target, ...source);

}

export const isMergebleObject = (item: any) => {
  return item && typeof item === 'object' && !Array.isArray(item);
}
