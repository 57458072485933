import {OnInit, OnDestroy, Component, ViewChild} from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { CoreConfigService } from '@core/services/config.service';

import {CoreTranslationService} from "@core/services/translation.service";
import {locale as italian} from "app/layout/components/footer/i18n/it";
import {locale as english} from "app/layout/components/footer/i18n/en";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import { NgxZendeskWebwidgetService } from 'ngx-zendesk-webwidget';
import {ShopB2bService} from "../../../services/shop-b2b.service";
import { ApplicationService } from "../../../../@core/services/application.service";
import { Theme } from "../../../../@core/types/app/theme";

@Component({
  selector: 'footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {
  public coreConfig: any;
  public year: number = new Date().getFullYear();
  sectionCustomTermsLink: string;
  sectionCustomWhoWeAreLink: string;
  sectionCustomHelpLink: string;
  sectionCustomLegalLink: string;
  sectionCustomPrivacyLink: string;

  // Private
  private _unsubscribeAll: Subject<any>;

  @ViewChild('modalTuleroWallet') modalTuleroWallet;

  /**
   * Constructor
   *
   * @param {CoreConfigService} _coreConfigService
   * @param _coreTranslationService
   * @param modalService
   * @param _shopB2bService
   */
  constructor(
    public _coreConfigService: CoreConfigService,
    private _coreTranslationService: CoreTranslationService,
    private modalService: NgbModal,
    private _applicationService: ApplicationService,
    private _shopB2bService: ShopB2bService
  ) {
    this._coreTranslationService.translate(italian, english);
    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to config changes
    this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
      this.coreConfig = config;
    });
    this._shopB2bService.getBehaviorSubject('wallet-modal').subscribe((res) => {
      if (res && (Object.keys(res).length > 0)) {
        this.modalOpenPrimary(this.modalTuleroWallet);
      }
    });

    this._applicationService.getTheme().then((themeObservable) => {
      themeObservable.subscribe((theme: Theme) => {
        if (theme.sections) {
          this.sectionCustomTermsLink = theme.sections.find(section => section.startsWith('custom:terms:url:')).replace('custom:terms:url:', '');
          this.sectionCustomWhoWeAreLink = theme.sections.find(section => section.startsWith('custom:who:url:')).replace('custom:who:url:', '');
          this.sectionCustomHelpLink = theme.sections.find(section => section.startsWith('custom:help:url:')).replace('custom:help:url:', '');
          this.sectionCustomLegalLink = theme.sections.find(section => section.startsWith('custom:legal:url:')).replace('custom:legal:url:', '');
          this.sectionCustomPrivacyLink = theme.sections.find(section => section.startsWith('custom:privacy:url:')).replace('custom:privacy:url:', '');
        }
      });
    });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  modalOpenPrimary(modalPrimary) {
    this.modalService.open(modalPrimary, {
      // centered: true,
      windowClass: 'modal modal-primary'
    });
  }
}
