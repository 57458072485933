import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toNumber'
})
export class ToNumberPipe implements PipeTransform {
  transform(price: string): any {
    return parseFloat(price.replace(',', '.'));
  }
}
