import {
  AfterViewInit,
  Component, ElementRef, EventEmitter,
  HostBinding,
  HostListener, Input,
  OnDestroy,
  OnInit, Output, SecurityContext, TemplateRef,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {MediaObserver} from '@angular/flex-layout';
import {DOCUMENT} from '@angular/common';
import * as _ from 'lodash';
import {BehaviorSubject, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';

import {AuthenticationService} from 'app/auth/service';
import {CoreSidebarService} from '@core/components/core-sidebar/core-sidebar.service';
import {CoreConfigService} from '@core/services/config.service';
import {CoreMediaService} from '@core/services/media.service';

import {User} from 'app/auth/models';
import {ActivatedRoute, Router} from '@angular/router';
import {ShopB2bService} from "../../../services/shop-b2b.service";
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {Injectable, Inject} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {SearchService} from "./navbar-search/search.service";
import {ThemeLogoService} from "../../../services/theme-logo.service";
import {CoreLoadingScreenService} from "../../../../@core/services/loading-screen.service";
import {Menu} from "../../../../@core/types/data/menu";
import {ApplicationService} from "../../../../@core/services/application.service";
import {Theme} from "../../../../@core/types/app/theme";
import {SalesService} from "../../../services/sales.service";
import {ToastrService} from "ngx-toastr";
import Swal from "sweetalert2";

import {AddressService} from "../../../services/address.service";
import {AnalyticsService} from "../../../services/analytics.service";

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NavbarComponent implements OnInit, OnDestroy, AfterViewInit {
  public rowSection;
  public searchText = '';
  public openSearchRef = false;
  public activeIndex = 0;
  public apiData;
  public pages = [];
  public files = [];
  public contacts = [];
  public pageSearchLimit;
  public horizontalMenu: boolean;
  public hiddenMenu: boolean;
  public notAvailable: any;
  public coreConfig: any;
  public currentSkin: string;
  public prevSkin: string;
  themeLogo: string;
  public currentUser: User = {} as User;
  showDropdownList = false;
  public suggestedProducts: any[] = [];

  public currentBusiness;
  public wallet = {
    balance: 0,
  };
  // public address = 'Via San Fransesco D\'Assisi, 35, 20121 Torino TO';
  public address: {
    id: string;
    street: string,
    number: string,
    zip: string,
    city: string,
    province: string,
    country: string,
    name: string,
  } = {
    id: '',
    street: '',
    number: '',
    zip: '',
    city: '',
    province: '',
    country: '',
    name: '',
  };
  fakeAddresses = [];
  public loaded = Promise.resolve(false);

  public languageOptions: any;
  public navigation: any;
  public selectedLanguage: any;

  public textSearch: string = '';

  @HostBinding('class.fixed-top')
  public isFixed = false;

  @HostBinding('class.navbar-static-style-on-scroll')
  public windowScrolled = false;
  @Output() deleteProductEvent = new EventEmitter<any>();

  @ViewChild('modalAgreements') modalAgreements;
  @ViewChild('modalCartNotAvailable') modalCartNotAvailable!: TemplateRef<any>;
  modalRef!: NgbModalRef;
  @ViewChild('openSearch') private _inputElement: ElementRef;
  @ViewChild('pageList') private _pageListElement: ElementRef;

  @Input()
  public clickedOverlay = new BehaviorSubject(false);


  public origin = window.location.origin;

  public agreementsSubmit = false;
  public agreementsOpened = false;

  public codeSearch = true;
  public fullSearchVisible = false;
  public selectedOption: string = 'codice';
  public clicked = false;
  public isInvalid = false;
  public showError = false;
  public inputClass = '';
  public searchBarText: string = 'Codice o una descrizione';

  public recharge = 3000;
  public percentage = 0.1;


  public navItems: Menu[] = [];

  public payments = [];

  filteredAddresses: any[] = [];

  searchTerm: string = '';

  public sectionTuleroWallet = false;
  public sectionCustomWallet = false;
  public sectionCustomNameWallet = '';

  // Add .navbar-static-style-on-scroll on scroll using HostListener & HostBinding
  public suppliers: any;
  public cart: any;

  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (
      (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop > 100) &&
      this.coreConfig.layout.navbar.type == 'navbar-static-top' &&
      this.coreConfig.layout.type == 'horizontal'
    ) {
      this.windowScrolled = true;
    } else if (
      (this.windowScrolled && window.pageYOffset) ||
      document.documentElement.scrollTop ||
      document.body.scrollTop < 10
    ) {
      this.windowScrolled = false;
    }
  }

  // Private
  private _unsubscribeAll: Subject<any>;

  public agreements = [];

  /**
   * Constructor
   *
   * @param {Router} _router
   * @param _route
   * @param {AuthenticationService} _authenticationService
   * @param {CoreConfigService} _coreConfigService
   * @param {CoreSidebarService} _coreSidebarService
   * @param {CoreMediaService} _coreMediaService
   * @param {MediaObserver} _mediaObserver
   * @param {TranslateService} _translateService
   * @param _shopB2bService
   * @param modalService
   * @param domSanitizer
   */
  constructor(
    @Inject(DOCUMENT) private document,
    private _router: Router,
    private _route: ActivatedRoute,
    private _authenticationService: AuthenticationService,
    private _salesService: SalesService,
    private _coreConfigService: CoreConfigService,
    private _coreMediaService: CoreMediaService,
    private _coreSidebarService: CoreSidebarService,
    private _mediaObserver: MediaObserver,
    public _translateService: TranslateService,
    private _shopB2bService: ShopB2bService,
    private modalService: NgbModal,
    private domSanitizer: DomSanitizer,
    private addressService: AddressService,
    private _searchService: SearchService,
    public themeLogoService: ThemeLogoService,
    private loadingScreenService: CoreLoadingScreenService,
    private _applicationService: ApplicationService,
    private toastr: ToastrService,
    private _analyticService: AnalyticsService
  ) {
    this.clickedOverlay.subscribe((data) => {
      if (data) {
        this.removeOverlay()
      }
    })
    this._salesService.cart.subscribe((data) => {
      if (!data) {
        return;
      }
      this.address = data.address;
    })
    this._authenticationService.currentUser$.subscribe(user => {
      this.currentUser = user;
    });

    this._authenticationService.business.subscribe(business => {
      this.loaded = Promise.resolve(false);
      if (!business || !business.addresses) {
        this.fakeAddresses = [];
        return;
      }

      this.currentBusiness = business;
      this.loaded = Promise.resolve(true);
      this.fakeAddresses = business.addresses.map((address) => {
        return {
          id: address._id,
          title: address.name,
          addressDetail: {
            streetAddress: address.street,
            city: address.city,
            province: address.province,
            CAP: address.zip,
          }
        }
      });
      this.filteredAddresses = this.fakeAddresses;
      this.payments = business.payments.map(payment => {
        payment.selected = false;
        payment.active = false;

        return payment;
      });
    });
    this._applicationService.getTheme().then((themeObservable) => {
      themeObservable.subscribe((theme: Theme) => {
        // Set primary and secondary colors dynamically
        if (theme.colors && theme.colors.primary && theme.colors.secondary) {
          this.themeLogoService.setThemeLogo(theme.app.logo.light);
        }
        if (theme.sections) {
          this.sectionTuleroWallet = theme.sections.includes('tulero:wallet');
          this.sectionCustomWallet = theme.sections.some((section) => section.startsWith('custom:wallet:'));
          if (this.sectionCustomWallet) {
            this.sectionCustomNameWallet = theme.sections.find((section) => section.startsWith('custom:wallet:')).split(':')[2];
          }
          this.rowSection = theme.sections.includes('tulero:gp');
        }
      });
    });

    //this._authenticationService.currentUser.subscribe(x => (this.currentUser = x));

    this.languageOptions = {
      en: {
        title: 'English',
        flag: 'us'
      },
      it: {
        title: 'Italiano',
        flag: 'it'
      },
    };
    this._applicationService.menu.subscribe((menu: Menu[]) => {
      this.navItems = menu
    })

    this._applicationService.getMenu()

    // Set the private defaults
    this._unsubscribeAll = new Subject();
    this.filteredAddresses = this.fakeAddresses;
  }

  // Public Methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle sidebar open
   *
   * @param key
   */

  searchUpdate() {
    this.document.querySelector('.app-content').classList.add('show-overlay');
    this.showDropdownList = true;
    const val = this.textSearch;
    if (val !== '') {
      if (this.codeSearch) {
        this._searchService.getSuggestionsByText(val)
          .then((response) => {
            if (response && response.length) {
              this.suggestedProducts = response.map((obj) => {
                return {
                  id: obj.code,
                  name: obj.name,
                  image: (obj.picture || "").trim() || "assets/images/logo/logo.png",
                  link: `/products/${obj.idList}/${obj.idProduct}`
                }
              });
            } else {
              this.suggestedProducts = [];
            }
          })
      } else {
        this._searchService.getApproximateSuggestionsByText(val)
          .then((response) => {
            if (response && response.length) {
              this.suggestedProducts = response.map((obj) => {
                return {
                  id: obj.code,
                  name: obj.name,
                  image: (obj.picture || "").trim() || "assets/images/logo/logo.png",
                  link: `/products/${obj.idList}/${obj.idProduct}`
                }
              });
            } else {
              this.suggestedProducts = [];
            }
          })

      }
    } else {
      this.showDropdownList = false;
      this.suggestedProducts = [];
      this.document.querySelector('.app-content').classList.remove('show-overlay');
    }
    this.autoSuggestion(event);
  }

  selectProduct(product: any) {
  }

  /**
   * Next Active Match
   */
  nextActiveMatch() {
    this.activeIndex = this.activeIndex < this.pageSearchLimit - 1 ? ++this.activeIndex : this.activeIndex;
  }

  /**
   * Previous Active Match
   */
  prevActiveMatch() {
    this.activeIndex = this.activeIndex > 0 ? --this.activeIndex : 0;
  }


  /**
   * Remove Overlay
   */
  removeOverlay() {
    this.document.querySelector('.app-content').classList.remove('show-overlay');
  }

  /**
   * Auto Suggestion
   *
   * @param event
   */
  autoSuggestion(event) {
    if (38 === event.keyCode) {
      return this.prevActiveMatch();
    }
    if (40 === event.keyCode) {
      return this.nextActiveMatch();
    }
    if (13 === event.keyCode) {
      // Navigate to activeIndex
      // ! Todo: Improve this code
      let current_item = this._pageListElement.nativeElement.getElementsByClassName('current_item');
      current_item[0]?.children[0].click();
    }
  }

  toggleSidebar(key): void {
    this._coreSidebarService.getSidebarRegistry(key).toggleOpen();
  }

  /**
   * Set the language
   *
   * @param language
   */
  setLanguage(language): void {
    // Set the selected language for the navbar on change
    this.selectedLanguage = language;

    // Use the selected language id for translations
    this._translateService.use(language);

    this._coreConfigService.setConfig({app: {appLanguage: language}}, {emitEvent: true});
  }

  /**
   * Toggle Dark Skin
   */
  toggleDarkSkin() {
    // Get the current skin
    this._coreConfigService
      .getConfig()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(config => {
        this.currentSkin = config.layout.skin;
      });

    // Toggle Dark skin with prevSkin skin
    this.prevSkin = localStorage.getItem('prevSkin');

    if (this.currentSkin === 'dark') {
      this._coreConfigService.setConfig(
        {layout: {skin: this.prevSkin ? this.prevSkin : 'default'}},
        {emitEvent: true}
      );
    } else {
      localStorage.setItem('prevSkin', this.currentSkin);
      this._coreConfigService.setConfig({layout: {skin: 'dark'}}, {emitEvent: true});
    }
  }

  /**
   * Logout method
   */
  logout() {
    this._authenticationService.signout()
      .then(() => {
        this._authenticationService.checkIfRedirect()
      })
  }

  search(text) {
    this._analyticService.trackSearchByCode(this.currentBusiness.code, text);
    this.showDropdownList = false;
    this.suggestedProducts = [];
    this.document.querySelector('.app-content').classList.remove('show-overlay');
    if (!this.codeSearch) {
      this._router.navigate(['products/code', text]);
    } else {
      this._router.navigate(['products/search', text]);
    }
  }

  searchMobile(text) {
    if (text.trim() === '') {
      // Display error message and highlight textbox
      this.showError = true;
      this.isInvalid = true;
      this.searchBarText = 'Si prega di inserire un termine di ricerca';
      this.inputClass = 'form-control invalid';
      setTimeout(() => {
        this.isInvalid = false;
        this.inputClass = 'form-control';
        this.showError = false;
        this.searchBarText = 'Codice o una descrizione';
      }, 1000);
    } else {
      this.clicked = true;
      document.querySelector('.search-btn')?.classList.toggle('white-bg');
      setTimeout(() => {
        this.clicked = false;
        document.querySelector('.search-btn')?.classList.toggle('orange-bg');
        this.fullSearchVisible = !this.fullSearchVisible;
      }, 1000);
      if (this.selectedOption === 'codice') {
        console.log('codice', text);
        this._router.navigate(['products/search', text]);
      } else if (this.selectedOption === 'testo') {
        console.log('testo', text);
        this._router.navigate(['products/text', text]);
      }
    }
  }

  openWallet($event) {
    $event.preventDefault();
    $event.stopPropagation();
    this._shopB2bService.getBehaviorSubject('wallet-modal').next({open: true});
  }

  modalOpenPrimary(modalPrimary, $event, options?) {
    if ($event) {
      $event.preventDefault();
      $event.stopPropagation();
    }
    options = options || {};
    const modalPrimaryOptions = {
      // centered: true,
      windowClass: 'modal modal-primary'
    }
    this.modalService.open(modalPrimary, {...modalPrimaryOptions, ...options});
  }

  modalOpenPrimary2(modalPrimary, $event, options) {
    this.modalService.dismissAll();
    if ($event) {
      $event.preventDefault();
      $event.stopPropagation();
    }
    options = options || {size: 'lg'};

    const modalPrimaryOptions = {
      // centered: true,
      windowClass: 'modal modal-primary'
    }
    this.modalService.open(modalPrimary, {...modalPrimaryOptions, ...options});
  }

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {

    this._authenticationService.currentUser$.subscribe(user => {
      this.currentUser = user;
    });

    this._searchService.getWalletAmount().then((response) => {
      this.wallet.balance = response;
    })
    //
    // this._authenticationService.business.subscribe((business) => {
    //   this.loaded = Promise.resolve(false);
    //   if (business) {
    //     this.currentBusiness = business;
    //     this.loaded = Promise.resolve(true);
    //   }
    // });
    // get the currentUser details from localStorage
    // this.currentUser = JSON.parse(localStorage.getItem('currentUser'));

    this._shopB2bService.getBehaviorSubject('text-search')
      .subscribe((data) => {
        if (typeof data == 'string') {
          this.textSearch = data;
        }
      });
    this._shopB2bService.getBehaviorSubject('business')
      .subscribe((result) => {
        if (!result || !result.data) {
          return
        }
        this.loaded = Promise.resolve(true);
        this.currentBusiness = result.data
        if (window.location.hostname !== 'localhost') {
          const networks = {
            'TULERO': 'business.tulero.it',
            'VAMOS': 'business.tulero.it',
            'PETRONAS': 'petronas.tulero.it',
            'EXTERNAL-GS': 'shop.tulero.it',
            'EUROMASTER': 'euromaster.tulero.it',
            'GOMME-SERVICE': 'gomme-service.tulero.it',
            'FIRSTSTOP': 'firststop.tulero.it',
            'AUTO180': 'auto180.tulero.it',
            'SUPERSERVICE': 'superservice.tulero.it',
            'KWIKFIT': 'kwikfit.tulero.it',
            'SOSCARSERVICE': 'soscarservice.tulero.it',
            'EKPARTS': 'ekparts.tulero.it',
            'CDGONE': 'cdgone.tulero.it',
          }

          if (networks[this.currentBusiness.network] && window.location.hostname !== networks[this.currentBusiness.network]) {
            window.location.href = `https://${networks[this.currentBusiness.network]}`;
          }
        }
        const wallet = this.currentBusiness.wallets.find((wallet) => wallet.type == 'CASHBACK');
        if (wallet) {
          this.wallet = wallet;
        }
      });
    // this._shopB2bService.getCurrentBusiness('business')

    // Subscribe to the config changes
    this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
      this.coreConfig = config;
      this.horizontalMenu = config.layout.type === 'horizontal';
      this.hiddenMenu = config.layout.menu.hidden === true;
      this.currentSkin = config.layout.skin;

      // Fix: for vertical layout if default navbar fixed-top than set isFixed = true
      if (this.coreConfig.layout.type === 'vertical') {
        setTimeout(() => {
          if (this.coreConfig.layout.navbar.type === 'fixed-top') {
            this.isFixed = true;
          }
        }, 0);
      }
    });

    // Horizontal Layout Only: Add class fixed-top to navbar below large screen
    if (this.coreConfig.layout.type == 'horizontal') {
      // On every media(screen) change
      this._coreMediaService.onMediaUpdate.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
        const isFixedTop = this._mediaObserver.isActive('bs-gt-xl');
        if (isFixedTop) {
          this.isFixed = false;
        } else {
          this.isFixed = true;
        }
      });
    }

    // Set the selected language from default languageOptions
    this.selectedLanguage = _.find(this.languageOptions, {
      id: this._translateService.currentLang
    });
    this._shopB2bService.getCurrentAgreements('agreements')
      .then((agreements) => {
        let flag = true
        agreements.forEach((agreement) => {
          // sanitize the url
          agreement.url = this.domSanitizer.bypassSecurityTrustResourceUrl(agreement.link);
          if (agreement.mandatory && !agreement.accepted) {
            flag = false
          }
        })
        this.agreementsSubmit = flag
        this.agreements = agreements;
        if (!this.agreementsOpened && this.agreements.length) {
          this.modalOpenPrimary(this.modalAgreements, null, {size: 'lg', backdrop: false});
          this.agreementsOpened = true
        }
      })
    this.themeLogo = this.themeLogoService.getThemeLogo();
    this.document.body.addEventListener('click', this.onBodyClick);
    this.filteredAddresses = this.fakeAddresses;
    this._salesService.cart.pipe(takeUntil(this._unsubscribeAll)).subscribe(async cart => {
      if (!cart) {
        return;
      }
      this.suppliers = await this._searchService.getSuppliers();
      this.cart = cart;
      this.notAvailable = this.cart?.changes;

      const fakeData: any[] = [
        {
          supplier: {
            code: 'ABC',
          },
          products: [
            {
              name: 'Product 1',
              code: 'P1',
              quantity: 2,
              price: 50,
              image: 'path/to/image1.jpg',
            },
            {
              name: 'Product 2',
              code: 'P2',
              quantity: 1,
              price: 70,
              image: 'path/to/image2.jpg',
            },
            {
              name: 'Product 1',
              code: 'P1',
              quantity: 2,
              price: 50,
              image: 'path/to/image1.jpg',
            },
            {
              name: 'Product 2',
              code: 'P2',
              quantity: 1,
              price: 70,
              image: 'path/to/image2.jpg',
            },
          ],
          active: true,
        },
        {
          supplier: {
            code: 'ABC',
          },
          products: [
            {
              name: 'Product 1',
              code: 'P1',
              quantity: 2,
              price: 50,
              image: 'path/to/image1.jpg',
            },
            {
              name: 'Product 2',
              code: 'P2',
              quantity: 1,
              price: 70,
              image: 'path/to/image2.jpg',
            },
          ],
          active: true,
        }
      ];


      // this.notAvailable = fakeData;
      if (this.notAvailable && this.notAvailable.length) {
        this.openModal();
      }

    });
  }

  openModal() {
    this.modalRef = this.modalService.open(this.modalCartNotAvailable, {
      size: 'lg', backdrop: true
    });


    this.modalRef.result.then(
      (result) => {
        console.log(`Modal closed with: ${result}`);
      },
      (reason) => {
        console.log(`Modal dismissed with: ${reason}`);
      }
    );
  }

  filterAddresses() {
    this.filteredAddresses = this.fakeAddresses.filter(address => {
      return [
        address.addressDetail?.streetAddress || "",
        address.addressDetail?.city || "",
        address.addressDetail?.province || "",
        address.addressDetail?.CAP || "",
        address.addressDetail?.country || "",
        address.title || ""
      ].join(' ').toLowerCase().includes(this.searchTerm.toLowerCase());
    });
  }


  onBodyClick = (event: MouseEvent) => {
    const targetElement = event.target as HTMLElement;

    const isClickInsideSearch = targetElement.closest('.suggestion-container') !== null;

    if (!isClickInsideSearch) {
      this.closeSuggestionBox();
    }
  };

  closeSuggestionBox() {
    this.showDropdownList = false;
    this.suggestedProducts = [];
    this.document.querySelector('.app-content').classList.remove('show-overlay');
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
    this.document.body.removeEventListener('click', this.onBodyClick);
  }

  ngAfterViewInit() {
    if (!this.agreementsOpened && this.agreements.length) {
      this.modalOpenPrimary(this.modalAgreements, null, {size: 'lg', backdrop: false});
      this.agreementsOpened = true
    }
  }

  toggleAgreement(agreement, event) {
    agreement.accept = event.target.checked;
    this.checkAgreements()
  }

  checkAgreements() {
    let flag = true
    this.agreements.forEach((agreement) => {
      if (agreement.mandatory && !agreement.accept) {
        flag = false
      }
    })
    this.agreementsSubmit = flag
  }

  sendAgreements() {
    this._shopB2bService.sendAgreements('agreements', this.agreements)
      .then((agreements) => {
        this.agreements = agreements;
        this.modalService.dismissAll();
      })
  }

  rechargeChanged() {
    let percentage = 0.06;
    if (this.recharge >= 30000) {
      percentage = 0.15;
    } else if (this.recharge >= 3000) {
      percentage = 0.1;
    } else if (this.recharge >= 2000) {
      percentage = 0.08;
    }
    this.percentage = percentage;
  }

  deleteAddress(i: number) {

  }

  editAddress(i: number) {

  }

  openAddress(i: number) {

  }

  suggestionClick(idProduct: any) {
    console.log('idProduct', idProduct);
    this.searchText = idProduct;
    this.showDropdownList = false;
    const selectedProduct = this.suggestedProducts.find((product) => product.id === idProduct);
    console.log('selectedProduct', selectedProduct);
    this.suggestedProducts = [];
    this.fullSearchVisible = !this.fullSearchVisible;
    this.document.querySelector('.app-content').classList.remove('show-overlay');

    this._router.navigate([selectedProduct.link]);
  }

  changeMainAddress(address: any) {
    this.address.name = address.title;
    this.address.street = address.addressDetail.streetAddress;
    this.address.number = address.addressDetail.number;
    this.address.city = address.addressDetail.city;
    this.address.province = address.addressDetail.province;
    this.address.zip = address.addressDetail.CAP;
    this.address.id = address.id;

    this._salesService.setAddress(address.id)
      .then((data) => {
        this._salesService.getCart();
      })
    this.modalService.dismissAll();
    this.document.querySelector('.app-content').classList.remove('show-overlay');
    this.addressService.emitAddressChanged();
  }

  updateProductToCart($event: any) {

  }

  deleteProductToCart($event: any) {

  }

  removeProduct(product, supplier) {
    this._searchService.removeFromCart(product.data.id, supplier.supplier.id).then(response => {
      this._salesService.getCart();
      this.toastr.error('Prodotto rimosso dal carrello', 'Successo', {
        timeOut: 1000,
        positionClass: 'toast-top-right',
      });


      this.deleteProductEvent.emit({product, supplier});
    })

    this._salesService.cart.pipe(takeUntil(this._unsubscribeAll)).subscribe(async cart => {
      this.suppliers = await this._searchService.getSuppliers();
      cart.suppliers.forEach(supplier => {
        supplier.active = true;
      });
      this.cart.data = cart;
      this.cart.loading = Promise.resolve(true);
    });
  }
  selectPayment(payment: any, $event: Event) {
    console.log('payment', payment);
    this.payments.forEach((payment: any) => {
      payment.selected = false;
    })
    if (payment.active) {
      console.log('payment', payment);
      payment.selected = ($event.target as HTMLInputElement).checked
    }
  }

  changeSelectedOption(type: string) {
    console.log('type', type);
    this.selectedOption = type;
    console.log('this.selectedOption', this.selectedOption);
    this.searchMobile(this.textSearch);
  }

  tokensFastCart() {
    const payment = this.payments.find((payment: any) => payment.selected);
    if (!payment) {
      Swal.fire('Seleziona un metodo di pagamento');
      return;
    }
    this._searchService.buyWallet(this.recharge, payment)
      .then((payment: any) => {
        if (payment.url) {
          window.location.href = payment.url
        }
        this._searchService.getWalletAmount();
      })
  }
}
