import * as theme from './theme'

import * as account from './account'

import * as users from './users'
import * as lastOrders from './last-orders'
import * as businesses from './businesses'
import * as groups from './groups'


export default {
  theme,
  account,
  users,
  lastOrders,
  businesses,
  groups
}
