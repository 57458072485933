const data = {};

const identities = [
  {
    user: {
      "id": "6548bf220f3b4a8b4c2f391e",
      "code": "0",
      "status": "CREATED",
      "username": "josue",
      "email": "josue@test.com",
      "emailVerified": false,
      "phone": "3311150344",
      "phoneVerified": false,
      "name": "josue",
      "surname": "ponce",
      "agreements": []
    }
  },
  {
    user: {
      "id": "6548bf220f3b4a8b4c2f391e",
      "code": "0",
      "status": "CREATED",
      "username": "josue",
      "email": "josue@test.com",
      "emailVerified": false,
      "phone": "3311150344",
      "phoneVerified": false,
      "name": "josue",
      "surname": "ponce",
      "agreements": []
    },
    business: {
      "id": "6548bf220f3b4a8b4c2f391e",
      "code": "0",
      "status": "CREATED",
      "name": "MEYLER SRL",
      "vat": "IT12938390015",
      "fiscalCode": "IT12938390015",
    }
  }
];

let currentIdentity = -1;

export const current = () => {
  return identities[currentIdentity];
}


export const signin = (body:any) => {
  currentIdentity += 1;
}
