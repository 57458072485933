import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'marginRelative'
})
export class MarginRelativePipe implements PipeTransform {
  transform(value: number, total: number): any {
    if (isNaN(value)) {
      return value;
    }
    if (typeof value === 'string') {
      value = parseFloat(value);
    }
    if (typeof total === 'string') {
      total = parseFloat(total);
    }
    if (total === 0) {
      return 0;
    }
    return ((total - value) / total);
  }
}
