<ng-container *ngIf="!item.hidden">
  <!-- item.url -->
  <a
    class="dropdown-item d-flex align-items-center justify-content-md-center"
    [ngClass]="item.classes"
    *ngIf="item.url && !item.externalUrl"
    [routerLink]="[item.url]"
    [target]="item.openInNewTab ? '_blank' : '_self'"
  >
    <ng-container *ngTemplateOutlet="itemContent"></ng-container>
  </a>

  <!-- item.externalUrl -->
  <a
    class="dropdown-item d-flex align-items-center"
    [ngClass]="item.classes"
    *ngIf="item.url && item.externalUrl"
    [href]="item.url"
    [target]="item.openInNewTab ? '_blank' : '_self'"
  >
    <ng-container *ngTemplateOutlet="itemContent"></ng-container>
  </a>

  <ng-template #itemContent>
    <span  [data-feather]="item.icon" class="mr-50 " *ngIf="item.icon"></span>
    <img class="icon-image mr-50 " src="/assets/images/icons/core-menu/{{item.iconAsset}}" *ngIf="item.iconAsset" alt="icon {{item.title}}"/>
    <span  [translate]="item.translate">{{ item.title }}</span>
    <span
      class="badge ml-auto"
      *ngIf="item.badge"
      [translate]="item.badge.translate"
      [ngClass]="item.badge.classes"
    >
      {{ item.badge.title }}
    </span>
  </ng-template>
</ng-container>
