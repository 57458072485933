export const list = {
  "rows": [
    {
      "_id": "PYkHpFCHBcFNkvl6",
      "name": "iPhone 11 Pro",
      "quantity": 25,
      "price": 999
    },
    {
      "_id": "oYqiS8dBUoIzNCQA",
      "name": "Huawei P30 Pro",
      "quantity": 15,
      "price": 679
    },
    {
      "_id": "szJDLxr59CP3aDc2",
      "name": "Samsung Galaxy S10 Plus",
      "quantity": 10,
      "price": 704
    }
  ],
  "total": 30,
  "page": 1,
  "pageSize": 10,
  "totalPages": 3
}
