export const locale = {
  lang: 'it',
  data: {
    MENU: {
      HOME: 'Home',
      SPARE_PARTS: 'Ricambi',
      PROMO: 'Promo',
      LUBRICANTS: 'Lubrificanti',
      BATTERIES: 'Batterie',
      TIRES: 'Pneumatici',
      GPL: 'GPL - Metano',
      UNIVERSAL: 'Universali',
      ACCESSORIES: 'Accessori',
    }
  }
};
