<!-- Horizontal Menu -->
<ng-container *ngFor="let item of menu">
  <!-- section -->
  <li
    core-menu-horizontal-collapsible
    *ngIf="
        item.type == 'section' && (item.role ? item.role.includes(currentUser.role) : false || item.role == undefined)
      "
    [item]="item"
    class="dropdown nav-item"
  ></li>

  <!-- item | if openInNewTab set routerLink & routerLinkActive blank -->
  <li
    core-menu-horizontal-item
    *ngIf="
        item.type == 'item' && (item.role ? item.role.includes(currentUser.role) : false || item.role == undefined)
      "
    [item]="item"
    [ngClass]="{ disabled: item.disabled === true }"
    [routerLinkActive]="!item.openInNewTab ? 'active' : ''"
    [routerLinkActiveOptions]="{ exact: item.exactMatch || false }"
  >
    <span [routerLink]="item.openInNewTab ? [] : [item.url]" class="d-none"></span>
  </li>

  <!-- collapsible -->
  <li
    core-menu-horizontal-collapsible
    *ngIf="
        item.type == 'collapsible' &&
        (item.role ? item.role.includes(currentUser.role) : false || item.role == undefined)
      "
    [item]="item"
    class="dropdown dropdown-submenu"
  ></li>


</ng-container>
<!--/ Horizontal Menu -->
