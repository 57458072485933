import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AddressService {
  private addressChanged = new Subject<void>();

  addressChanged$ = this.addressChanged.asObservable();

  emitAddressChanged() {
    this.addressChanged.next();
  }
}
