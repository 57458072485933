import { Injectable } from '@angular/core';
declare var gtag: any;

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  private validateString(input: string, name: string): void {
    if (input === null || input === undefined || input.trim() === '') {
      throw new Error(`${name} cannot be null, undefined, or an empty string.`);
    }
  }

  private validateNumber(input: number, name: string): void {
    if (input === null || input === undefined || isNaN(input)) {
      throw new Error(`${name} cannot be null, undefined, or NaN.`);
    }
  }

  trackEvent(eventName: string, eventDetails: string, eventCategory: string) {
    this.validateString(eventName, 'eventName');
    this.validateString(eventDetails, 'eventDetails');
    this.validateString(eventCategory, 'eventCategory');

    gtag('event', eventName, {
      'event_category': eventCategory,
      'event_label': eventName,
      'value': eventDetails
    });
  }

  userTrack(businessCode: string, businessName: string) {
    this.validateString(businessCode, 'businessCode');
    this.validateString(businessName, 'businessName');

    console.log('User ID:', businessCode + ' - ' + businessName);
    gtag('set', {
      'custom_map': { 'dimension1': 'business_code' }
    });
    gtag('event', 'set_business_code', { 'business_code': businessCode + ' - ' + businessName });
    gtag('set', 'user_properties', {
      'business_code': businessCode + ' - ' + businessName
    });
    gtag('event', 'login', {
      'event_category': 'user',
      'event_label': 'User ' + businessCode + ' Login'
    });
  }

  trackSearchByCode(businessCode: string, searchCode: string) {
    this.validateString(businessCode, 'businessCode');
    this.validateString(searchCode, 'searchCode');

    console.log('Search Code:', businessCode + ' search ' + searchCode);
    gtag('set', {
      'custom_map': { 'dimension2': 'search_code' }
    });
    gtag('event', 'set_search_code', { 'search_code': businessCode + ' search ' + searchCode });
    gtag('set', 'user_properties', {
      'search_code': businessCode + ' search ' + searchCode
    });
  }

  trackPlateSearch(businessCode: string, searchPlate: string) {
    this.validateString(businessCode, 'businessCode');
    this.validateString(searchPlate, 'searchPlate');

    console.log('Search Plate:', businessCode + ' search ' + searchPlate);
    gtag('set', {
      'custom_map': { 'dimension3': 'search_plate' }
    });
    gtag('event', 'set_search_plate', { 'search_plate': businessCode + ' search ' + searchPlate });
    gtag('set', 'user_properties', {
      'search_plate': businessCode + ' search ' + searchPlate
    });
  }

  trackAddingProduct(businessCode: string, productCode: string) {
    this.validateString(businessCode, 'businessCode');
    this.validateString(productCode, 'productCode');

    console.log('Adding Product:', businessCode + ' add ' + productCode);
    gtag('set', {
      'custom_map': { 'dimension4': 'add_product' }
    });
    gtag('event', 'set_add_product', { 'add_product': businessCode + ' cart-add ' + productCode });
    gtag('set', 'user_properties', {
      'add_product': businessCode + ' cart-add ' + productCode
    });
  }

  trackCheckout(businessCode: string, amount: number) {
    this.validateString(businessCode, 'businessCode');
    this.validateNumber(amount, 'amount');

    console.log('Checkout:', businessCode + ' checkout ' + amount);
    const amountInEuroFormat = '€' + amount.toFixed(2).replace('.', ',');
    gtag('set', {
      'custom_map': { 'dimension5': 'checkout' }
    });
    gtag('event', 'set_checkout', { 'checkout': businessCode + ' checkout ' + amountInEuroFormat });
    gtag('set', 'user_properties', {
      'checkout': businessCode + ' checkout ' + amountInEuroFormat
    });
  }
}
