import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';

import {BehaviorSubject, Observable} from 'rxjs';
import {ApiService} from "../../../../../@core/services/api.service";

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  // Public
  public search = '';
  public apiData = [];
  public onApiDataChange: BehaviorSubject<any>;
  public onIsBookmarkOpenChange: BehaviorSubject<any>;
  private apiUrl = '/api/users/products';

  /**
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private apiService: ApiService) {
  }

  /**
   * Get Search Data
   */
  getSearchData(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this.apiData = [];
      this.onApiDataChange.next(this.apiData);
      resolve(this.apiData);
      // this._httpClient.get('api/search-data').subscribe((response: any) => {
      //   this.apiData = response;
      //   this.onApiDataChange.next(this.apiData);
      //   resolve(this.apiData);
      // }, reject);
    });
  }

  // getSuggestionsByCode(code: string): Observable<any> {
  //   const url = `${this.apiUrl}/suggest-code/${code}`;
  //   return this._httpClient.get<any>(url);
  // }

  getSuggestionsByText(text: string): Promise<any> {
    return this.apiService.make('search', 'suggestion', {text})
      .then((data: any) => {
        return data as any[];
      })
      .catch((error: any) => {
        console.error('Error fetching Versions:', error);
        return [];
      });
  }

  getApproximateSuggestionsByText(text: string): Promise<any> {
    return this.apiService.make('search', 'approximateSuggestions', {text})
      .then((data: any) => {
        return data as any[];
      })
      .catch((error: any) => {
        console.error('Error fetching Versions:', error);
        return [];
      });
  }


  getSuppliers(): Promise<any> {
    return this.apiService.make('search', 'getSuppliers')
      .then((data: any) => {
        return data as any[];
      })
      .catch((error: any) => {
        console.error('Error fetching Versions:', error);
        return [];
      });
  }


  getWarehouses(): Promise<any> {
    return this.apiService.make('search', 'getWarehouses')
      .then((data: any) => {
        return data as any[];
      })
      .catch((error: any) => {
        console.error('Error fetching Versions:', error);
        return [];
      });
  }

  getSupplierProducts(id, code): Promise<any> {
    return this.apiService.make('search', 'getSupplierProducts', {id, code})
      .then((data: any) => {
        return data as any[];
      })
      .catch((error: any) => {
        console.error('Error fetching Versions:', error);
        return [];
      });
  }

  productTextSearch(text: string, skipCheck: boolean){
    const searchText = text;
    return this.apiService.make('search', 'productTextSearch', {text, searchText, skipCheck})
      .then((data: any) => {
        data.sort((a, b) => {
          if (a.certified === b.certified) {
            return 0;
          } else if (a.certified) {
            return -1;
          } else {
            return 1;
          }
        });
        return data as any[];
      })
      .catch((error: any) => {
        console.error('Error fetching productTestSearch:', error);
        return [];
      });
  }

  searchByOem(idVehicle: string, idItem: string, oeCode: string, skipCheck: boolean) {
    const idVehicle2 = idVehicle;
    const idItem2 = idItem;
    const oeCode2 = oeCode;
    return this.apiService.make('search', 'searchFromOEM', {idVehicle, idItem, oeCode, idVehicle2, idItem2, oeCode2, skipCheck})
      .then((data: any) => {
        return data as any[];
      })
      .catch((error: any) => {
        console.error('Error fetching productTestSearch:', error);
        return [];
      });
  }

  searchSuppliersByOem(id: string, idVehicle: string, idItem: string, oeCode: string) {
    return this.apiService.make('search', 'suppliersFromOEM', {id, idVehicle, idItem, oeCode})
      .then((data: any) => {
        return data as any[];
      })
      .catch((error: any) => {
        console.error('Error fetching productTestSearch:', error);
        return [];
      });
  }

  getFromListkrOekr(listkr: string, oekr: string, skipCheck: boolean) {
    const listkr2 = listkr;
    const oekr2 = oekr;
    return this.apiService.make('search', 'getFromList', {listkr, oekr, listkr2, oekr2, skipCheck})
      .then((data: any) => {
        return data as any[];
      })
      .catch((error: any) => {
        console.error('Error fetching productTestSearch:', error);
        return [];
      });
  }

  suppliergetFromListkrOekr(id: string, listkr: string, oekr: string) {
    return this.apiService.make('search', 'suppliersFromList', {id, listkr, oekr})
      .then((data: any) => {
        return data as any[];
      })
      .catch((error: any) => {
        console.error('Error fetching productTestSearch:', error);
        return [];
      });
  }

  getProductMultipleSuppliers(idBrand: string, idKromeda: string) {
    return this.apiService.make('search', 'getProductMultipleSuppliers', {idBrand, idKromeda})
      .then((data: any) => {
        return data as any[];
      })
      .catch((error: any) => {
        console.error('Error fetching productTestSearch:', error);
        return [];
      });
  }

  getSingleProductFromSupplier(idBrand: string, idKromeda: string, idSupplier: string) {
    return this.apiService.make('search', 'getSingleProductFromSupplier', {idSupplier, idBrand, idKromeda})
      .then((data: any) => {
        return data as any;
      })
      .catch((error: any) => {
        console.error('Error fetching productTestSearch:', error);
        return ;
      });
  }

  getVinSettings() {
    return this.apiService.make('search', 'vinSettings')
      .then((data: any) => {
        return data as any[];
      })
      .catch((error: any) => {
        console.error('Error fetching productTestSearch:', error);
        return [];
      });
  }

  getPlateSettings() {
    return this.apiService.make('search', 'plateSettings')
      .then((data: any) => {
        return data as any[];
      })
      .catch((error: any) => {
        console.error('Error fetching productTestSearch:', error);
        return [];
      });
  }

  getWalletAmount() {
    return this.apiService.make('search', 'walletAvailability')
      .then((data: any) => {
        return data as any;
      })
      .catch((error: any) => {
        console.error('Error fetching productTestSearch:', error);
        return [];
      });
  }

  getRedirect() {
    return this.apiService.make('search', 'redirect')
      .then((data: any) => {
        return data?.session?.data?.redirect || "";
      })
      .catch((error: any) => {
        console.error('Error fetching productTestSearch:', error);
        return [];
      });
  }

  getWalletType(type: string) {
    return this.apiService.make('search', 'getWalletType', {type})
      .then((data: any) => {
        return data as any;
      })
      .catch((error: any) => {
        console.error('Error fetching productTestSearch:', error);
        return [];
      });
  }

  getProductsByCategory(idCategory: string, skipCheck: boolean) {
    const id2 = idCategory;
    return this.apiService.make('search', 'customCategoriesProducts', {id: idCategory, id2, skipCheck})
      .then((data: any) => {
        return data as any;
      })
      .catch((error: any) => {
        console.error('Error fetching productTestSearch:', error);
        return [];
      });
  }

  suppliergetFromCategory(idSupplier: string, idCategory: any) {
    return this.apiService.make('search', 'supplierCategoriesProducts', {id: idCategory, idSupplier})
      .then((data: any) => {
        return data as any;
      })
      .catch((error: any) => {
        console.error('Error fetching productTestSearch:', error);
        return [];
      });
  }

  getCustomerOrders() {
    return this.apiService.make('search', 'customerOrders')
      .then((data: any) => {
        return data as any;
      })
      .catch((error: any) => {
        console.error('Error fetching productTestSearch:', error);
        return [];
      });
  }

  getSingleOrder(code: string, year: string) {
    return this.apiService.make('search', 'singleOrder', {code, year})
      .then((data: any) => {
        return data as any;
      })
      .catch((error: any) => {
        console.error('Error fetching productTestSearch:', error);
        return [];
      });
  }

  addToCart(product: string, quantity: number, supplier: string) {
    return this.apiService.make('search', 'addToCart', {}, {body: {product, quantity, supplier}})
      .then((data: any) => {
        return data as any;
      })
      .catch((error: any) => {
        console.error('Error fetching addToCart:', error);
        return [];
      });
  }

  setToCart(product: string, quantity: number, supplier: string) {
    return this.apiService.make('search', 'setToCart', {}, {body: {product, quantity, supplier}})
      .then((data: any) => {
        return data as any;
      })
      .catch((error: any) => {
        console.error('Error setToCart:', error);
        return [];
      });
  }
  removeFromCart(product: string, supplier: string) {
    return this.apiService.make('search', 'removeFromCart', {}, {body: {product, supplier}})
      .then((data: any) => {
        return data as any;
      })
      .catch((error: any) => {
        console.error('removeFromCart:', error);
        return [];
      });
  }
  removeCart() {
    return this.apiService.make('search', 'removeCart')
      .then((data: any) => {
        return data as any;
      })
      .catch((error: any) => {
        console.error('removeCart:', error);
        return [];
      });
  }

  buyVin(amount: number, payment: any) {
    return this.apiService.make('search', 'buyVin', {}, { body: {quantity: amount, payment}})
      .then((data: any) => {
        return data as any;
      })
      .catch((error: any) => {
        console.error('buyWallet:', error);
        return [];
      });
  }

  buyPlate(amount: number, payment: any) {
    return this.apiService.make('search', 'buyPlate', {}, { body: {quantity: amount, payment}})
      .then((data: any) => {
        return data as any;
      })
      .catch((error: any) => {
        console.error('buyWallet:', error);
        return [];
      });
  }

  buyWallet(amount: number, payment: any) {
    return this.apiService.make('search', 'buyWallet', {}, { body: {amount, payment}})
      .then((data: any) => {
        return data as any;
      })
      .catch((error: any) => {
        console.error('buyWallet:', error);
        return [];
      });
  }

  buyPrime(payment: any) {
    return this.apiService.make('search', 'buyPrime', {}, { body: {payment}})
      .then((data: any) => {
        return data as any;
      })
      .catch((error: any) => {
        console.error('buyWallet:', error);
        return [];
      });
  }

  stopAllTheRequests() {
    this.apiService.stopAllTheRequests();
  }

  searchList(products: any[], warehouses: any[]) {
    return this.apiService.make('search', 'searchList', {}, { body: {products, warehouses}})
      .then((data: any) => {
        return data as any[];
      })
  }

  searchProducts (type: string, params: any, searchCross: boolean = true) {
    return this.apiService.make('search', 'allSearches', {}, { body: {type, params, searchCross}})
      .then((data: any) => {
        return data as any[];
      })
      .catch((error: any) => {
        console.log('Error fetching productTestSearch:', error);
        return [];
      });
  }
}
