import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isURL'
})
export class IsURLPipe implements PipeTransform {
  transform(string: string): boolean {
    let url;
    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }
    return url.protocol === "http:" || url.protocol === "https:";
  }
}
