import { Theme, ThemeAnimation, ThemeSkin } from "@core/types/app/theme";

export const AppTheme: Theme = {
  app: {
    name: 'Admin Tulero',
    title: 'Admin Tulero',
    logo: {
      light: '/assets/images/logo/logo-light.png',
      lightSquare: '/assets/images/logo/logo-light-square.png',
      dark: '/assets/images/logo/logo-dark.png',
      darkSquare: '/assets/images/logo/logo-dark-square.png',
    },
    icon: {
      light: '/assets/images/favicon/favicon-36x36.png',
      dark: '/assets/images/favicon/favicon-36x36.png'
    },
    language: 'it'
  },
  layout: {
    skin: ThemeSkin.LIGHT,
    animation: ThemeAnimation.ZOOM_IN,
    sidebar: {
      hidden: false
    },
    header: {
      hidden: false
    },
    footer: {
      hidden: false
    }
  },
  colors: {
    primary: '',
    secondary: '',
    success: '',
    info: '',
    warning: '',
    danger: ''
  }
}
